<script setup>

import { VueTelInput } from "vue-tel-input";
// import "vue-tel-input/vue-tel-input.css";
import { onMounted, ref, inject, computed, defineAsyncComponent } from "vue";
import { router, usePage, Link } from "@inertiajs/vue3";
import { useField, useForm } from "vee-validate";
import { useDisplay } from "vuetify";

import { useToast } from "vue-toastification";

const ContactForm3D = defineAsyncComponent(() =>
    import("@/Components/ContactForm3D.vue")
);
const page = usePage();
const errors = computed(() => page.props.errors);
const __t = inject("__t");

const $toast = useToast();

const { mdAndDown, lgAndUp, smAndDown } = useDisplay();

const isPageLoaded = ref(false);
const isSending = ref(false);
const route = page.props.getRouteName;

onMounted(() => {
  if (!import.meta.env.SSR) {
    setTimeout(() => {
      if (document.readyState === "complete") {
        isPageLoaded.value = true;
      }
    }, 500);
  }
});
const { handleSubmit, handleReset, isSubmitting } = useForm({
  validationSchema: {
    name(value) {
      if (value?.length >= 2) {
        return true;
      } else {
        return __t("nameNeedsToBe2Char");
      }
    },
    phone(value) {
      if (!value) return true;
      if (value?.length > 9 && /[0-9-]+/.test(value)) return true;
      return false;
    },
    email(value) {
      if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;
      return __t("emailValidation");
    },
    message(value) {
      if (value?.length >= 5) {
        return true;
      } else {
        return __t("messageValidation");
      }
    },
  },
});
const employeeNumberList = ref([
  { title: "1-10", value: "1-10" },
  { title: "11-50", value: "11-50" },
  { title: "51-200", value: "51-200" },
  { title: "201-500", value: "201-500" },
  { title: "501-1000", value: "501-1000" },
  { title: "1000+", value: "1000+" },
]);

const submited = ref(false);
const name = ref("");
const surName = ref("");
const companyName = ref("");
const employeeNumber = ref("");
const phone = ref("");
const email = ref("");
const message = ref("");
const grecaptcharesponse = ref();
const title = ref();
const submit = async () => {

  title.value = document.title;
  isSending.value = true;
  axios
    .post("https://api.ps4tek.com/api/v1/contact", {
      name: name.value + " " + surName.value,
      email: email.value,
      phone: phone.value,
      title: title.value,
      msg: ` اسم الشركة : ${companyName.value} - عدد الموظفين : ${employeeNumber.value} - الرسالة : ${message.value}`,
      "g-recaptcha-response": grecaptcharesponse.value,    })
    .then((res) => {
      isSending.value = false;
      submited.value = true;

      $toast.success(__t("sent"));

      handleReset();
    })
    .catch((error) => {
      isSending.value = false;

      $toast.error(error);
    });
};

const isSendDisabled = () => {
  if (phone.value === undefined || phone.value?.length === 0) {
    return true;
  }
  if (name.value === undefined || name.value?.length === 0) {
    return true;
  }
  if (email.value === undefined || email.value?.length === 0) {
    return true;
  }
  // if (message.value === undefined || message.value?.length === 0) {
  //   return true;
  // }
  if (companyName.value === undefined || companyName.value?.length === 0) {
    return true;
  }
  if (
    employeeNumber.value === undefined ||
    employeeNumber.value?.length === 0
  ) {
    return true;
  }
  return false;
};

const locale = computed(() => {
  return page.props.locale;
});
</script>
<template>
  <div id="contact"
    class="backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] p-4 p-md-8 mt-8"
    data-aos="fade-up">
    <!-- data-aos="fade-up" -->
    <div class="py-2 4 sm:py-3 2">
      <v-row class="items-center justify-center" no-gutters>
        <v-col cols="12" md="6" class="flex items-center mt-20 md:mt-0">
          <div class="z-20 h-[400px] w-[200px] bg-b ase" v-if="lgAndUp && isPageLoaded">
            <v-no-ssr>
              <ContactForm3D />
            </v-no-ssr>
          </div>
          <div class="space-y-5">
            <h1 class="text-4xl font-bold text-primarydark">
              {{ __t("letUsContactYou") }}
            </h1>
            <p>{{ __t("letUsContactYouDes") }}</p>
            <h2 class="text-sm font-bold">
              {{ __t("contactUs") }}
            </h2>
            <div class="">
              <div class="flex justify-between">
                <ul class="flex flex-col gap-x-7">
                  <li class="flex flex-row items-center gap-3 text-lg">
                    <a class="flex items-center justify-center gap-2"
                      href="https://api.whatsapp.com/send/?phone=905366948138" target="_blank">
                      <div class="flex items-center justify-center w-10 h-10 my-3 border-2 rounded-full border-green">
                        <v-icon icon="mdi-phone" size="25" class="text-green" color="green" />
                      </div>
                      <p dir="ltr">+90 536 694 81 38</p>
                    </a>
                  </li>
                  <li class="flex flex-row items-center gap-3 text-lg">
                    <a class="flex items-center justify-center gap-2"
                      href="https://api.whatsapp.com/send/?phone=905525598842" target="_blank">
                      <div class="flex items-center justify-center w-10 h-10 my-3 border-2 rounded-full border-green">
                        <v-icon icon="mdi-phone" size="25" class="text-green" color="green" />
                      </div>
                      <p dir="ltr">+90 552 559 88 42</p>
                    </a>
                  </li>
                  <li class="flex flex-row items-center gap-3 text-lg">
                    <a class="flex items-center justify-center gap-2" :href="'mailto:info@ps4tek.com'" target="_blank">
                      <div class="flex items-center justify-center w-10 h-10 my-3 border-2 rounded-full border-primary">
                        <v-icon icon="mdi-email" size="25" class="text-primary" color="primary" />
                      </div>
                      <p>info@3kode.com</p>
                    </a>
                  </li>
                </ul>
                <div class="z-20 h-[200px] w-[100px] bg-b ase" v-if="!lgAndUp && isPageLoaded">
                  <v-no-ssr>
                    <ContactForm3D />

                  </v-no-ssr>
                </div>
              </div>
              <div class="">
                <ul class="flex items-start justify-end w-full gap-1 mt-2 mb-5 mb-md-3">
                  <li>
                    <a href="https://www.instagram.com/three.kode" target="_blank">
                      <v-btn aria-label="instagram" icon variant="text">
                        <v-icon icon="mdi-instagram" size="30" />
                      </v-btn>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/3kode" target="_blank">
                      <v-btn aria-label="facebook" icon variant="text">
                        <v-icon icon="mdi-facebook" size="30" />
                      </v-btn>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/three.kode" target="_blank">
                      <v-btn aria-label="linkedin" icon variant="text">
                        <v-icon icon="mdi-linkedin" size="30" />
                      </v-btn>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <form class="mt-2 space-y-5" @submit.prevent="submit" v-if="!submited">
              <v-row>
                <v-col cols="12">
                  <h2 class="font-bold text-black text-[20px]">
                    معلومات التواصل
                  </h2>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field class="rounded" hide-details v-model="name" :error="!!errors?.name"
                    :error-messages="errors?.name" type="text" rounded="lg" variant="outlined" color="primarydark"
                    bg-color="transparent" :placeholder="__t('name')" label="الأسم الأول"
                    :dir="locale === 'ar' ? 'rtl' : 'ltr'">
                    <template #append-inner>
                      <v-icon icon="mdi-form-textbox" size="30" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field class="rounded" hide-details v-model="surName" :error="!!errors?.surName"
                    :error-messages="errors?.surName" type="text" rounded="lg" variant="outlined" color="primarydark"
                    bg-color="transparent" placeholder="اللقب" label="الأسم الأخير"
                    :dir="locale === 'ar' ? 'rtl' : 'ltr'">
                    <template #append-inner>
                      <v-icon icon="mdi-form-textbox" size="30" />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <vue-tel-input hide-details dir="ltr" mode="international" v-model="phone" :inputOptions="{
                    placeholder: __t('phone'),
                    autocomplete: 'on',
                    required: true,
                  }" :dropdownOptions="{
                        showDialCodeInList: true,
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showSearchBox: true,
                      }" :preferredCountries="['AE', 'TR', 'IQ', 'SA', 'LY', 'SY']" :class="`rounded-lg h-[50px]   z-50 ${errors?.phone ? 'error' : ''
                        }`">
                  </vue-tel-input>
                  <p v-if="errors?.phone" class="m-0 mt-2 text-xs ms-5 text-red">
                    {{ __t("phoneValidation") }}
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field class="rounded" hide-details :error="!!errors?.email" :error-messages="errors?.email"
                    type="text" rounded="lg" variant="outlined" color="primarydark" bg-color="transparent"
                    v-model="email" :placeholder="__t('email')" label="البريد الالكتروني"
                    :dir="locale === 'ar' ? 'rtl' : 'ltr'">
                    <template #append-inner>
                      <v-icon icon="mdi-email-open-outline" size="30" />

                      <!-- <Icon name="ph:envelope" size="30" color="white" /> -->
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <h2 class="font-bold text-black text-[20px]">
                    اخبرنا بالمزيد عنك ..
                  </h2>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field class="rounded" hide-details :error="!!errors?.companyName"
                    :error-messages="errors?.companyName" type="text" rounded="lg" variant="outlined"
                    color="primarydark" bg-color="transparent" v-model="companyName" placeholder="اسم الشركة"
                    label="اسم الشركة / المؤسسة" :dir="locale === 'ar' ? 'rtl' : 'ltr'">
                    <template #append-inner>
                      <v-icon icon="mdi-office-building" size="30" />

                      <!-- <Icon name="ph:envelope" size="30" color="white" /> -->
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="employeeNumber" hide-details :items="employeeNumberList" item-title="title"
                    item-value="value" :dir="locale === 'ar' ? 'rtl' : 'ltr'" label="عدد الموظفين" />
                </v-col>
                <v-col cols="12">
                  <h2 class="font-bold text-black text-[18px]">
                    هل لديك اي سؤال ؟
                  </h2>
                </v-col>

                <v-col cols="12" class="mb-0 pd-0">
                  <v-textarea class="rounded py-" hide-details v-model="message" type="textarea"
                    :error="!!errors?.message" :error-messages="errors?.message" rounded="lg" variant="outlined"
                   color="primarydark" :placeholder="__t('message')"
                    :dir="locale === 'ar' ? 'rtl' : 'ltr'">
                    <template #append-inner>
                      <v-icon icon="mdi-chat-outline" size="30" />
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>

              <v-btn @click.prevent="submit()" :disabled="isSendDisabled()" class="w-full" rounded="lg" color="primary"
                :loading="isSending">
                <div class="font-bold text-[15px]">
                  {{ __t("send") }}
                </div>
              </v-btn>
            </form>
            <div v-else>
              <h1
                class="px-4 text-black text-[20px] min-h-[400px] flex flex-col text-center items-center justify-center">
                <v-icon icon="mdi-check-circle" size="60" color="green" />
                <br />
                تهانينا! لقد اتخذت خطوة ذكية نحو مستقبل متطور. سيقوم
                فريقنا المتخصص بالتواصل معك قريباً لمساعدتك في بدء رحلتك نحو
                التميز مع خدماتنا المتطورة
              </h1>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.vue-tel-input {
  border: 1px solid rgb(0 0 0 / 40%);
}

:deep(.error) {
  border: 1px solid red !important;
}

.vue-tel-input:hover {
  border: 1px solid rgba(256, 256, 256, 1);
  box-shadow: none;
}

.vue-tel-input:focus-within {
  border: 2px solid rgba(var(--v-theme-primarydark), 1);
  box-shadow: none;
  transition: all ease-in 400ms;
}

:deep(.vti__dropdown-list) {
  background-color: black !important;
  color: rgb(4, 4, 4) !important;
  border-radius: 8px;
}

:deep(.highlighted) {
  background-color: rgba(var(--v-theme-primarydark), 1) !important;
}

:deep(.vti__dropdown):hover {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 8px;
}

:deep(.open) {
  background-color: rgba(256, 256, 256, 0.1) !important;
  border-radius: 8px;
}
</style>
